import { cacheAdapterEnhancer } from 'axios-extensions'
import LRUCache from 'lru-cache'
const ONE_DAY = 1000 * 60 * 60 * 24

const defaultCache = new LRUCache({ maxAge: ONE_DAY })

/**
 * axios plugin
 *
 * @param {*} root0 - app context
 * @param {*} root0.$axios - axios
 * @param {*} root0.$cookies - cookie-universal-nuxt
 */
export default function ({ $axios, $cookies }) {
  const defaults = $axios.defaults
  defaults.adapter = cacheAdapterEnhancer(defaults.adapter, {
    enabledByDefault: false,
    defaultCache
  })

  $axios.onRequest((config) => {
    config.requireToken = config.requireToken === undefined || config.requireToken
    const originalRequest = config

    const issueToken = function () {
      return new Promise((resolve) => {
        if (originalRequest.headers.Authorization && ($cookies.get('storedToken') !== 'undefined')) {
          const authToken = originalRequest.headers.Authorization.split(' ')[1]
          $cookies.set('storedToken', authToken, { path: '/', maxAge: ONE_DAY / 1000 })
          resolve(authToken)
        } else if ((!$cookies.get('storedToken') || $cookies.get('storedToken') === '' || $cookies.get('storedToken') === 'undefined') && config.requireToken) {
          return $axios.post(process.env.API_ENDPOINT + '/api/users',
            require('qs').stringify({
              device_name: 'browser'
            }), {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).then((response) => {
            const authToken = response.data.token
            $cookies.set('storedToken', authToken, { path: '/', maxAge: ONE_DAY / 1000 })
            resolve(authToken)
          })
        } else {
          resolve($cookies.get('storedToken'))
        }
      })
    }

    const isCreateUser = function (config) {
      return config.url === process.env.API_ENDPOINT + '/api/users' && (config.method === 'POST' || config.method === 'post')
    }

    if (!isCreateUser(config)) {
      return issueToken().then((token) => {
        if (/\/web-list(?:\?|$)/.test(originalRequest.url) && token) {
          originalRequest.url = process.env.API_ENDPOINT + '/api/artworks'
          originalRequest.cache = false
        }

        if (token) {
          originalRequest.headers.Authorization = 'Bearer ' + token
        }
        return Promise.resolve(originalRequest)
      })
    }

    return config
  })
}
