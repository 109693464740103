// @ts-nocheck
export function getBreadCrumbs (path: string, idTitles: string[] = []) {
  if (path.endsWith('/')) { path = path.slice(0, -1) }
  const pathsArr = path.split('/')
  if (pathsArr.length < 2) { return [] }

  let currentHref = process.env.API_ENDPOINT

  return pathsArr
    .map((pathEl, index) => {
      // console.log(pathEl)
      currentHref += `${pathEl}/`

      const localeTranslate = this.$t(`breadcrumbs.${pathEl.length > 0 ? pathEl : 'index'}`)
      return {
        title: index !== 0 && (Number.isInteger(+pathEl) || index === 2) && idTitles.length > 0
          ? idTitles.shift()
          : localeTranslate.includes('breadcrumbs.') ? '404' : localeTranslate,
        href: index === 0 ? '/?page=true' : currentHref
      }
    })
}
